
<router-outlet></router-outlet>


<!-- <app-homepage></app-homepage>  -->
<!-- <app-registration></app-registration>  -->
<!-- <app-check-your-email></app-check-your-email> -->
<!-- <app-uploading-page></app-uploading-page> -->
<!-- <app-file-sent></app-file-sent> -->
<!-- <app-login-page></app-login-page> -->
<!-- <app-oimovie-page></app-oimovie-page> -->