import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { S3 } from 'aws-sdk';
import { catchError, forkJoin, from, map,Observable, throwError } from 'rxjs';
import { APIConstant } from 'src/app/constant/api';

@Injectable({
  providedIn: 'root'
})

export class CollaborateService {
  bucket = 'prevueit-doc';
  region = 'ap-south-1';
  accessKeyId = 'AKIAY4FN7ANJB6IKB6GZ';
  secretAccessKey = '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';

  s3: S3;

  constructor(private httpClient: HttpClient) { 
    this.s3 = new S3({
    accessKeyId: this.accessKeyId,
    secretAccessKey: this.secretAccessKey,
    region: this.region
  });
}

  // public downloadFile(fileName: any, url: any) {
  //   return this.httpClient.get(url,
  //     {
  //       params: fileName,
  //       responseType: 'blob',
  //       reportProgress: true,
  //       observe: 'events',
  //       headers: new HttpHeaders({ 'Content-Type': 'application/octet-stream' })
  //     }
  //   );
  // }

  public downloadFile(Name: any): Observable<any> {
    const urlParts = Name.split('/');
    const params = {
      Bucket: this.bucket,
      Key: urlParts[urlParts.length - 1],
    };

    return new Observable((observer) => {
      this.s3.headObject(params, (headErr, headData) => {
        if (headErr) {
          observer.error(headErr);
        } else {
          this.s3.getObject(params, (getErr, getData) => {
            if (getErr) {
              observer.error(getErr);
            } else {
              observer.next(getData.Body);
            }
            observer.complete();
          });
        }
      });
    });
  }
  
  generatePreSignedUrl(s3ObjectUrl: string): Observable<string> {
    // Replace 'your-bucket-name' with your actual S3 bucket name
    const bucketName = this.bucket;
    const key = s3ObjectUrl?.split('/');

    const params = {
      Bucket: bucketName,
      Key: key[key?.length - 1],
      Expires: 3600, // The URL will expire in 1 hour (you can adjust this as needed)
    };

    return from(this.s3.getSignedUrlPromise('getObject', params)).pipe(
      catchError(error => {
        console.error('Error generating pre-signed URL:', error);
        return throwError('Error generating pre-signed URL');
      })
    );
  }

  generatePreSignedUrls(s3ObjectUrls: string[]): Observable<string[]> {
    const preSignedUrlObservables: Observable<string>[] = [];

    for (const s3ObjectUrl of s3ObjectUrls) {
      const bucketName = this.bucket;
      const key = s3ObjectUrl.split('/');

      const params = {
        Bucket: bucketName,
        Key: key[key.length - 1],
        Expires: 3600, // The URL will expire in 1 hour (you can adjust this as needed)
      };

      const preSignedUrlObservable = new Observable<string>((observer) => {
        this.s3.getSignedUrl('getObject', params, (err, url) => {
          if (err) {
            observer.error(err);
          } else {
            observer.next(url);
          }
          observer.complete();
        });
      });

      preSignedUrlObservables.push(preSignedUrlObservable);
    }

    return forkJoin(preSignedUrlObservables).pipe(
      catchError(error => {
        console.error('Error generating pre-signed URLs:', error);
        return throwError('Error generating pre-signed URLs');
      })
    );
  }

  public getFilesByUserId(fileType: any, userId: any, iFolderId: any): Observable<any> {
    const url = `${APIConstant.getFilesbyUserId}`
    return this.httpClient.get(url.replace('{fileType}', fileType).replace('{iUserId}', userId).replace('{iFolderId}', iFolderId))
  }

  public getFileSharedUserEmail(userEmail: any, fileType: any): Observable<any> {
    const url = `${APIConstant.getFileSharedUserEmail}`
    return this.httpClient.get(url.replace('{userEmail}', userEmail).replace('{fileType}', fileType))
  }

  public getSentFilesbyUserId(userId: any): Observable<any> {
    const url = `${APIConstant.getSentFilesbyUserId}`
    return this.httpClient.get(url.replace('{iUserId}', userId))
  }

  public getFileByFileId(iFileId: any): Observable<any> {
    const url = `${APIConstant.getFilebyFileId}`
    return this.httpClient.get(url.replace('{iFileId}', iFileId))
  }

  public getCommentsById(iFileId: any): Observable<any> {
    const url = `${APIConstant.getCommentsById}`
    return this.httpClient.get(url.replace('{iFileId}', iFileId))
  }

  public postUpdateUser(updateUserModel: any): Observable<any> {
    const url = `${APIConstant.updateUser}`
    return this.httpClient.post(url, updateUserModel)
  }

  public postAddSharedUser(model: any): Observable<any> {
    const url = `${APIConstant.postAddSharedUser}`
    return this.httpClient.post(url, model)
  }

  public getStoreViewCount(iFileId: any,): Observable<any> {
    const url = `${APIConstant.getStoreViewCount}`
    return this.httpClient.get(url.replace('{iFileId}', iFileId));
  }
  public getStoreViewCountbyUrl(shrUrl: any,): Observable<any> {
    const url = `${APIConstant.getStoreViewCountbyUrl}`
    return this.httpClient.get(url.replace('{url}', shrUrl));
  }
  public postComments(data: any): Observable<any> {
    const url = `${APIConstant.postCommentDetails}`
    return this.httpClient.post(url, data)
  }

  public postCommentsGetURL(data: any): Observable<any> {
    const formData = new FormData();
    formData.append('comment', data);
    const url = `${APIConstant.postComment}`
    return this.httpClient.post(url, formData)
  }


  public getUserById(iUserId: any): Observable<any> {
    const url = `${APIConstant.userById}`
    return this.httpClient.get(url.replace('{iUserId}', iUserId))
  }

  public getUpdateFileReview(iFileId: any, userEmail: any): Observable<any> {
    const url = `${APIConstant.getUpdateFileReview}`
    return this.httpClient.get(url.replace('{iFileId}', iFileId).replace('{userEmail}', userEmail));
  }

  public getLikeComment(iCommentId: any,): Observable<any> {
    const url = `${APIConstant.getLikeComment}`
    return this.httpClient.get(url.replace('{iCommentId}', iCommentId));
  }

  public postUploadProfilePic(data: any): Promise<any> {
    const formData = new FormData();
    formData.append('profilePic', data);
    const url = `${APIConstant.postUploadProfilePic}`
    return this.httpClient.post(url, formData).toPromise()
  }

  public getRemoveSharedFile(iFileId: any, userEmails: any, iFolderId: any): Observable<any> {
    const url = `${APIConstant.getRemoveSharedFile}`
    return this.httpClient.get(url.replace('{iFileId}', iFileId).replace('{iFolderId}', iFolderId).replace('{userEmails}', userEmails))
  }

  public deleteFile(iFileId: any): Observable<any> {
    const url = `${APIConstant.getDeleteFile}`
    return this.httpClient.get(url.replace('{iFileId}', iFileId))
  }

  public addRemoveFavourite(iFileFolderId: any, isFavourite: any, isFolder: any): Observable<any> {
    const url = `${APIConstant.addRemoveFavourite}`
    return this.httpClient.get(url.replace('{iFileFolderId}', iFileFolderId).replace('{isFavourite}', isFavourite).replace('{isFolder}', isFolder))
  }
  public renameFile(iFileFolderId: any, newName: any, isFolder: any): Observable<any> {
    const url = `${APIConstant.renameFileName}`
    return this.httpClient.get(url.replace('{iFileFolderId}', iFileFolderId).replace('{newName}', newName).replace('{isFolder}', isFolder))
  }

  public createFolder(model: any): Observable<any> {
    const url = `${APIConstant.createFolder}`
    return this.httpClient.post(url, model)
  }

  public getusers(): Observable<any> {
    const url = `${APIConstant.getalluser}`
    return this.httpClient.get(url)
  }

  public movetoFolder(iFileId: any, iFolderId: any): Observable<any> {
    const url = `${APIConstant.movetoFolder}`
    return this.httpClient.get(url.replace('{iFolderId}', iFolderId).replace('{iFileId}', iFileId))
  }

  public getFolderByUserid(iUserInfoId: any): Observable<any> {
    const url = `${APIConstant.getFolderByUserid}`
    return this.httpClient.get(url.replace('{iUserInfoId}', iUserInfoId))
  }

  public deleteFolder(iFolderId: any): Observable<any> {
    const url = `${APIConstant.deleteFolder}`
    return this.httpClient.get(url.replace('{iFolderId}', iFolderId))
  }

  public fetFolderDetailsWithFile(iFolderId: any): Observable<any> {
    const url = `${APIConstant.fetFolderDetailsWithFile}`
    return this.httpClient.get(url.replace('{iFolderId}', iFolderId))
  }

  public duplicateFile(iFileId: any, iUserId: any): Observable<any> {
    const url = `${APIConstant.duplicateFile}`
    return this.httpClient.get(url.replace('{iFileId}', iFileId).replace('{iUserId}', iUserId))
  }

  async getFile(url: string) {
    const httpOptions = {
      responseType: "blob" as "json"
    };
    const res = await this.httpClient
      .get(url, httpOptions)
      .toPromise()
      .catch((err: HttpErrorResponse) => {
        const error = err.error;
        return error;
      });
    return res;
  }

  public getSearchFileLists(fileType: any, iUserId: any ,userEmail:any): Observable<any> {
    const url = `${APIConstant.getSearchFileLists}`
    return this.httpClient.get(url.replace('{fileType}', fileType).replace('{iUserId}', iUserId).replace('{userEmail}', userEmail))
  }

}
