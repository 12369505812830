import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { MessageService } from 'primeng/api';
import { LayoutService } from 'src/app/layout/services/layout.service';
import { AWSS3Service } from '../service/azure-blob-storage.service';
import { SharedService } from '../service/shared.service';
import { CollaborateService } from 'src/app/collaborate/services/collaborate.service';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['../../../../css/mystyle.css',
  '../../../../vendor/bootstrap/css/bootstrap.min.css',
  '../../../../vendor/fontawesome-free/css/all.min.css'],
  providers: [MessageService]
})
export class UploaderComponent implements OnInit{
  @Input() files:any = [];
  @Output() showUploaderProgress = new EventEmitter<string>();
  @Output() hideUploader = new EventEmitter<string>();
  @Output() dashboardLoadEvent = new EventEmitter<any>();
  optionsAnimation: AnimationOptions = {
    path: "assets/animations/Globe to attach file.json"
  };
  // public files: any = [];
  public allFiles:any[] = [];
  // public emailTo : any;
  public description: string = '';
  public localStorage :any;
  // public fileUrlData : any = [];
  public fileURLWithName : any =[];
  public progressStart = false;
  public progress = 0;
  public currentFileName = '';
  public usedBytes:any;
  public remainingBytes:any;
  public totalBytes:any;
  public currentStorage:any;
  public inputEmail = false;
  public check = false;
  public sendFileForm: FormGroup;
  public isSubmitted = false;
  paramsData: any;
  tempProgressValue: any = 0;
  oncancel2: any =false;


  constructor(private router:Router, 
    private layoutService:LayoutService,
    private messageService: MessageService,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private service: CollaborateService,
    // private blobService: AzureBlobStorageService,
    private s3Service: AWSS3Service) {
      // this.sharedService.progressValue.subscribe(res=>this.progress = res)
      this.sendFileForm = new FormGroup({
        email: new FormControl('',[Validators.required,Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,3}$"),this.validateEmail]),
      })
      
     }


  ngOnInit(): void {
    this.localStorage = JSON.parse(localStorage.getItem('userInfo') || '{}');
    this. loadInitialData();
    this.sharedService.oncanceluploadObservable().subscribe((isFalse: boolean) => {
      this.oncancel2 = isFalse;
    });
  }

  loadInitialData(){
    this.sharedService.getFileSizeByUserId(this.localStorage.userId).subscribe(res=>{
      if(res){
        this.usedBytes = res.responseData ? Number(res.responseData.usedBytes) : 0;
        this.remainingBytes = res.responseData ? Number(res.responseData.remainingBytes) : 0;
        this.totalBytes = res.responseData ? Number(res.responseData.totalBytes) : 0;
      }
    }); 
  }

  onUpload(event:any) {
  if(localStorage.getItem('userInfo') === null){
    this.router.navigate(['auth/login']);
  }else{
    this.fileInArray(event.files[0]);
    this.showRemainingStorage();
  }
}

onDNDSelected(event:any){
  if(localStorage.getItem('userInfo') === null){
    this.router.navigate(['auth/login'])
  }else{
    this.fileInArray(event);
    this.showRemainingStorage();
  }
}

onSelected(event:any){
  if(localStorage.getItem('userInfo') === null){
    this.router.navigate(['auth/login'])
  }else{
  if(event.target.files.length > 0){
    this.fileInArray(event.target.files[0])
    this.showRemainingStorage();
  }}
}

inputToEmail(){
  this.inputEmail = !this.inputEmail
  this.check = this.inputEmail
  // this.sendFileForm.get('email')?.setValue('')
  if(!this.inputEmail){
    this.sendFileForm.get('email')?.setValidators([Validators.required,this.validateEmail]);
    this.sendFileForm.get('email')?.setValue('')
    this.sendFileForm.updateValueAndValidity();
    this.isSubmitted = false;
  }else{
    this.sendFileForm.get('email')?.clearValidators();
    this.sendFileForm.get('email')?.setValue('')
    this.sendFileForm.updateValueAndValidity();
    this.isSubmitted = false;
  }
 }

fromGlobe(){
  document.getElementById('fileInput')?.click();
  }

  remove(index:any){
    this.files.splice(index,1)
  }

fileInArray(file:File){
    if(file.size > 5368709120){
    this.showSelectedFileExceedsLimit();
    }else{
      this.files.push(file);
    }
  }

showRemainingStorage(){
  for(let i=0 ;i<this.files.length;i++){
    this.remainingBytes = this.remainingBytes - Number(this.files[i].size)
  }
  this.currentStorage = this.remainingBytes;
}

sendFileGetURL(){
  this.isSubmitted = true;
  if(this.sendFileForm.valid){
    if(this.files.length != 0){
      this.showUploaderProgress.emit("1");
      this.progressStart=true;
      this.sharedService.progress.next(true);
      this.sharedService.setfiles(this.files);
    for(let i=0 ;i<this.files.length;i++){
      // this.getURL(this.files[i]);
      if (this.isLargeFile(this.files[i])) {
        this.getURLFromAWS1(this.files[i]);
      } else {
        this.getURLFromAWS(this.files[i]);
      }
    }
  }
  else{
    this.sendFileForm.reset();
    this.description = '';
    this.showFailure();
  }
  }
}

isLargeFile(file: File): boolean {
  console.log(" size",file.size)
  // Define a threshold size for large files, for example, 10 MB
  const thresholdSize = 10 * 1024 * 1024; // 10 MB in bytes
  return file.size > thresholdSize;
}

timeStamp(name:string){
  let time = new Date();
  const timeStamp = 'File_'+time.getFullYear()+(time.getMonth()+1)+time.getDate()+time.getTime()+'.'+(name.substring(name.lastIndexOf('.') + 1));
  let model = {
   originalName :name,
   fileName :timeStamp,
  //  fileURL: '',
  }
  this.fileURLWithName.push(model)
  return timeStamp
  }

//  async getURLFromAzure(file:File){
//   // this.sharedService.progress.next(true);
//   let Responseurl = await this.blobService.uploadFiles(file,this.timeStamp(file.name), () => {});
//   this.fileURLWithName.map((file:any)=>{if(file.fileName === Responseurl._name){file.fileURL = Responseurl.url}});
//   if(this.fileURLWithName.length === this.files.length && this.progress ===100 && this.checkFileURL()){
  
//   this.sendFileInfo();
//   }
//  }

async getURLFromAWS(file: File) {
  try {
  let Responseurl = await this.s3Service.uploadFiles(file, this.timeStamp(file.name), () => {},(progress: number) => {
    // You can handle progress updates here if needed
    console.log(`Upload progress: ${progress}%`);
    // You might want to update your UI with the progress value
    this.tempProgressValue = Math.round(progress);
    this.sharedService.setProgressValue(this.tempProgressValue);
  }
);
console.log("object",this.fileURLWithName.map((file: any) => { if (file.fileName === Responseurl.name) { file.fileURL = Responseurl.url } }))
  this.fileURLWithName.map((file: any) => { if (file.fileName === Responseurl.name) { file.fileURL = Responseurl.url } });
  // if (this.fileURLWithName.length === this.files.length && this.progress === 100 && this.checkFileURL()) {
  //   this.sendFileInfo();
  // }
  // this.sendFileInfo();
  this.sendFileInfoSinglefile(file,Responseurl.url);
} catch (error) {
  // Handle the error as needed
  console.error('Error getting URL from AWS:', error);
} /* finally {
  // Always call sendFileInfo, even if an error occurs
  // this.sendFileInfo(file);
  
} */
}

async getURLFromAWS1(file: File) {
  try {
    // Use the modified uploadFilesWithProgress method for multipart upload
    let Responseurl = await this.s3Service.uploadFiles1(file,this.timeStamp(file.name),() => {},(progress: number) => {
        // You can handle progress updates here if needed
        console.log(`Upload progress: ${progress}%`);
        // You might want to update your UI with the progress value
        this.tempProgressValue = Math.round(progress);
        this.sharedService.setProgressValue(this.tempProgressValue);
      }
    );

    // Update fileURL in your array based on the fileName
    this.fileURLWithName.forEach((fileInfo: any) => {
      if (fileInfo.originalName === file.name) { // Assuming fileInfo.fileName is the actual file name property
        fileInfo.fileURL = Responseurl.url; // Update with the actual location if available
      }
    });

    // Check if all files have URLs, progress is 100, and other conditions are met
    if (this.checkFileURL() && this.progress === 100 && this.fileURLWithName.length === this.files.length) {
      // this.sendFileInfo(file);
      this.sendFileInfoSinglefile(file,Responseurl.url);
    }
    this.sendFileInfoSinglefile(file,Responseurl.url);
    // this.sendFileInfo();
  } catch (error) {
    // Handle the error as needed
    console.error('Error getting URL from AWS:', error);
  } /* finally {
    // Always call sendFileInfo, even if an error occurs
    // this.sendFileInfo(file);
    this.sendFileInfoSinglefile(file,Responseurl.url);
  } */
}

 checkFileURL(){
  if(this.fileURLWithName.find((f:any)=>!f.fileURL)){
    return false;
  }else{
    return true;
  }
 }

 sendFileInfo(file: File) {
  let folderId = 0;
  this.route.queryParams.subscribe(params => {
    this.paramsData = params;
      folderId = parseInt(this.paramsData['folderId']);
  });


  for (let i = 0; i < this.files.length; i++) {
    const model = {
        // iFileId: 0,
        iFolderId: folderId,
        fileOriginalName: this.files[i].name,
        fileUrl: this.fileURLWithName.find((file: any) => file.originalName === this.files[i].name)?.fileURL,
        iUserInfoId: Number(this.localStorage.userId),
        sharedEmail: this.sendFileForm.get('email')?.value ? this.sendFileForm.get('email')?.value :null,
        contentType: this.files[i].type,
        description: this.description,
        fileSize: (this.files[i].size).toString(),
        fileType: this.getFileType(this.files[i].type, this.files[i].name),
        longDescription: "string",
        // viewedCount: 0,
        uploadedBy: Number(this.localStorage.userId),
      };

      this.layoutService.postFileDetails(model).subscribe(res => {
        if (res) {
          if (i + 1 === this.fileURLWithName.length) {
            this.progressStart = false;
            this.progress = 0;
            this.sharedService.progress.next(false);
            this.files = [];
            this.fileURLWithName = [];
          }
        }
      });
    }
  }

  async sendFileInfoSinglefile(file: File, url: any) {
    let folderId = 0;
    this.route.queryParams.subscribe(params => {
      this.paramsData = params;
        folderId = parseInt(this.paramsData['folderId']);
    });
  
    // for (let i = 0; i < this.files.length; i++) {
      const model = {
          // iFileId: 0,
          iFolderId: folderId,
          fileOriginalName: file.name,
          fileUrl: url,
          iUserInfoId: Number(this.localStorage.userId),
          sharedEmail: this.sendFileForm.get('email')?.value ? this.sendFileForm.get('email')?.value :null,
          contentType: file.type,
          description: this.description,
          fileSize: (file.size).toString(),
          fileType: this.getFileType(file.type, file.name),
          longDescription: "string",
          // viewedCount: 0,
          uploadedBy: Number(this.localStorage.userId),
        };
  
        this.layoutService.postFileDetails(model).subscribe(res => {
          this.reloadDashboard();
          if (res) {
            // if (i + 1 === this.fileURLWithName.length) {
              this.progressStart = false;
              this.progress = 0;
              this.sharedService.progress.next(false);
              this.files = [];
              this.fileURLWithName = [];
            }
          // }
          this.sharedService.DashboardData$.subscribe((files) => {
            for(let i = 0; i < files.length; i++)
          if(this.oncancel2 && files[i].fileOriginalName == model.fileOriginalName){
            this.service.deleteFile(files[i].iFileId).subscribe((res) => {
              this.reloadDashboard();
            console.log("Delete complete", res);
            });  
          }
        });
        });
      // }
    this.sendFileForm.reset();
    this.description = '';
    }

    reloadDashboard(){
      this.dashboardLoadEvent.next(0);
    }
 
  getFileType(type: any, filename: any) {
    const content = type.split('/',2)[0];
    const extenstion = filename.split('.')[1];
    switch(content) { 
      case 'video': { 
        return 'video'
      }
      case 'audio': { 
        return 'audio'
      } 
      case 'image': { 
        return 'image'
      } 
      case 'application': { 
        return (extenstion == 'zip' || extenstion == 'rar') ? 'others' : 'document';
      }
      case 'text': { 
        return 'document'
      }
      default: { 
        return '' 
      } 
  }
}



cancelUploder(){
  this.hideUploader.emit("1");
  this.sendFileForm.reset();
  this.description = '';
}

formatSizeUnits(bytes: any) {
  bytes = Number(bytes);
  if (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + " GB"; }
  else if (bytes >= 1048576) { bytes = (bytes / 1048576).toFixed(2) + " MB"; }
  else if (bytes >= 1024) { bytes = (bytes / 1024).toFixed(2) + " KB"; }
  else if (bytes > 1) { bytes = bytes + " bytes"; }
  else if (bytes == 1) { bytes = bytes + " byte"; }
  else { bytes = "0 bytes"; }
  return bytes;
}

validateEmail(control: AbstractControl<any, any>): ValidationErrors | null {
  const sharedEmail = control.value?.replace(/\s+/g, '')
  if(sharedEmail?.indexOf(',') !== -1){
    let emailData = sharedEmail?.split(',');
    if(emailData?.find((email:string)=> !email?.match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$"))){
      return { emailInvalid: true };
    }else{
      return null;
    }
  }else{
    if(!sharedEmail?.match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$")){
      return { emailInvalid: true };
    }else{
      return null;
    }
  }
}

showLoginSuccessFully() {
  this.messageService.add({severity:'success', summary: 'Success', detail: 'Login Successfull'});
}

showFileUploadSuccessFully(data:string) {
 this.messageService.add({ severity: 'custom', summary: data, detail: 'File Uploaded Successfully', icon: 'pi-upload' });
}

showSelectedFileExceedsLimit() {
  this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Selected File Exceeded 5GB Limit'});
}

showFailure() {
  this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select file to upload'});
}

}
