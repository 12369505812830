 <footer class="sticky-footer  ">
  <div class="container">
      <div class="row ng-black">
          <div class="col-xl-2 col-lg-2 col-sm-2 w-20">
          <div class="footer-widget-box">
              <h4 class="footer_widget_title">Features</h4>
              <ul class="footer_link">
                  <li><a href="/pages/features/#page-2" target="_blank" rel="noopener noreferrer">Time stamping</a></li>
                  <li><a href="/pages/features/#page-2" target="_blank" rel="noopener noreferrer">Audio comments</a></li>
                  <li><a href="/pages/features/#page-2" target="_blank" rel="noopener noreferrer">Preview Files</a></li>
              </ul>
          </div>
      </div>
      <div class="col-xl-2 col-lg-2 col-sm-2 w-20">
          <div class="footer-widget-box">
              <h4 class="footer_widget_title">Supports</h4>
              <ul class="footer_link">
                  <li><a href="#">Get in Touch</a></li>
                  <li><a href="#">Videos</a></li>
                  <li><a href="#">Blogs</a></li>
              </ul>
          </div>
      </div>
      <div class="col-xl-2 col-lg-2 col-sm-2 w-20">
        <div class="footer-logo-widget-box text-center">
          <img src="../../../../assets/images/logo.png" class="footer-logo" />
          <p class="brand-text">Bharatransfer</p>
          <ul class="footer_link footer_link d-flex justify-content-center">
            <li class="social-media"><a href="https://www.youtube.com/channel/UCLZEV5aeO7mr0ESG0ISeObA" target="_blank" rel="noopener noreferrer"><i class="fab fa-youtube fa-xs"></i></a></li>
            <li class="social-media"><a href="https://www.linkedin.com/company/bharatransfer/" target="_blank" rel="noopener noreferrer"><i class="fab fa-linkedin-in fa-xs"></i></a></li>
          </ul>
        </div>
    </div>
      <div class="col-xl-2 col-lg-2 col-sm-2 w-20">
          <div class="footer-widget-box">
              <h4 class="footer_widget_title">Pricing</h4>
              <ul class="footer_link">
                  <li><a href="/pages/features/#page-3" target="_blank" rel="noopener noreferrer">Buy Pro</a></li>
                  <!-- <li><a href="#">Free Trial</a></li> -->
              </ul>
          </div>
      </div>
      <div class="col-xl-2 col-lg-2 col-sm-2 w-20">
          <div class="footer-widget-box">
              <h4 class="footer_widget_title">Legal</h4>
              <ul class="footer_link">
                  <li><a href="/pages/terms-conditions" target="_blank" rel="noopener noreferrer">Terms of Use</a></li>
                  <!-- <li><a href="#">Cookies</a></li> -->
                  <li><a href="/pages/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy</a></li>
              </ul>
          </div>
      </div>
      </div>
  </div>
  <div class="row no-gutters ng-black">
    <div class="col-xl-12 col-lg-12 col-sm-12">
        <div class="copyright text-center">
            <p>© 2024 Bharatransfer - All Rights Reserved</p>
        </div>
    </div>
</div>
</footer>
