<div class="mobile-menu">
  <ul class="bottom-navbar-nav">
      <li class="bottom-nav-item active">
          <a href="index.html" class="bottom-nav-link">
              <i class="fas fa-fw fa-home"></i>
              <span>Home</span>
          </a>
      </li>
      <li class="bottom-nav-item">
          <a href="#" class="bottom-nav-link">
              <i class="fas fa-fw fa-users"></i>
              <span>Hot</span>
          </a>
      </li>
      <li class="bottom-nav-item">
          <a href="#" class="bottom-nav-link">
              <i class="fas fa-fw fa-user-alt"></i>
              <span>Channel</span>
          </a>
      </li>
      <li class="bottom-nav-item">
          <a href="#" class="bottom-nav-link">
              <i class="fas fa-fw fa-video"></i>
              <span>Video</span>
          </a>
      </li>
      <li class="bottom-nav-item">
          <a href="#" class="bottom-nav-link">
              <i class="fas fa-fw fa-cloud-upload-alt"></i>
              <span>Upload</span>
          </a>
      </li>
  </ul>
</div>
<nav class="navbar navbar-expand static-top my-navbar1 sticky-top">
  <a class="navbar-brand mr-1 nav-set-logo1" [routerLink]="['/collaborate/dashboard']" [queryParams]="{fileType: 0, folderId: 0}"><img class="img-fluid   " alt src="assets/img/logo.png" /></a>
  <!-- <button class="btn btn-link btn-sm text-secondary order-1 order-sm-0" id="sidebarToggle">
      <i class="fas fa-bars"></i>
  </button> -->

  <form class="d-none d-md-inline-block form-inline ml-auto mr-0 my-2 my-md-0 my-navbar-search">
      <div class="">
          <!-- <input type="text" class="form-control" [suggestions]="suggestions" placeholder="Search Dashboard" (onSelect)="requestSelectFile($event)"
          (completeMethod)="requestSearchBar($event)" (onClear)="requestSearchBar($event)" field="fileOriginalName"/> -->
           <!-- <p-autoComplete class="form-control" [suggestions]="suggestions" placeholder="Search Dashboard" (onSelect)="requestSelectFile($event)"
          (completeMethod)="requestSearchBar($event)" (onClear)="requestSearchBar($event)" field="fileOriginalName">
          <ng-template let-file pTemplate="item">

              <div class="flex align-items-center gap-2 folder-auto-box">
                  <div class="folder-details-box">
                      <div>
                          <i class="pi pi-fw pi-folder mr-3"></i>
                      </div>
                      <div class="filename">
                          {{ file.fileOriginalName }}
                          <span class="d-block foldername">Folder : {{file.folderName}}</span>

                      </div>
                  </div>
                  <div class="date-box">
                      {{file.fileUploadedDate | date : 'MMM dd'}}
                  </div>
              </div>
          </ng-template>
      </p-autoComplete> -->
          <div class="" style="display: flex;">
              <!-- <button class="btn btn-light" type="button">
                  <i class="fas fa-search"></i>
              </button> -->
              <p-autoComplete #autoCompleteRef class="input-group input-group-append" [(ngModel)]="text" [suggestions]="suggestions" placeholder="Search" (onSelect)="requestSelectFile($event)"
              (completeMethod)="requestSearchBar($event)" (onClear)="requestSearchBar($event)" field="fileOriginalName" style="width: 383px !important;">
              <ng-template let-file pTemplate="item">
    
                  <div class="flex align-items-center gap-2 folder-auto-box">
                      <div class="folder-details-box">
                          <div>
                              <i class="pi pi-fw pi-folder mr-3"></i>
                          </div>
                          <div class="filename">
                              {{ file.fileOriginalName }}
                              <span class="d-block foldername">Folder : {{file.folderName}}</span>
    
                          </div>
                      </div>
                      <div class="date-box">
                          {{file.fileUploadedDate | date : 'MMM dd'}}
                      </div>
                  </div>
              </ng-template>
          </p-autoComplete>
          <button class="btn btn-light" type="button" style="z-index: 100; margin-left: -45px; background: inherit;" (click)="clearAutoComplete()">
            <i class="fas fa-xmark" style="opacity: 0.2;" ></i>
          </button>
          </div>
      </div>
  </form>

  <ul class="navbar-nav ml-auto ml-md-0 top-right-navbar">
      <!-- <li class="nav-item dropdown no-arrow mx-1">
          <a class="nav-link dropdown-toggle"  id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fas fa-bell fa-fw"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-right notification-box" aria-labelledby="alertsDropdown">
              <p class="notify-bar-title">Notification  <i class="fa fa-times-circle close-icon"></i></p>
              <div class="notification_list">
                  <ul>
                      <li>
                          <div class="notify_icon">
                              <img src="/assets/img/user-icon.png" class="icon">
                          </div>
                          <div class="notify_data">
                              <h5 class="title">Jimmy Morgan.</h5>
                              <p class="sub_title">Sent you a message.</p>
                          </div>
                          <div class="notify_time">
                              <p>1h 20m</p>
                          </div>
                      </li>
                      <li>
                          <div class="notify_icon">
                              <img src="/assets/img/user-icon.png" class="icon">
                          </div>
                          <div class="notify_data">
                              <h5 class="title">Jimmy Morgan.</h5>
                              <p class="sub_title">Sent you a message.</p>
                          </div>
                          <div class="notify_time">
                              <p>2days ago</p>
                          </div>
                      </li>
                      <li>
                          <div class="notify_icon">
                              <img src="/assets/img/user-icon.png" class="icon">
                          </div>
                          <div class="notify_data">
                              <h5 class="title">Jimmy Morgan.</h5>
                              <p class="sub_title">Send you a email.</p>
                          </div>
                          <div class="notify_time">
                              <p>4days ago</p>
                          </div>
                      </li>
                  </ul>
              </div>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item  btn all-notification-btn" href="#">View all notifications</a>
          </div>
      </li> -->
      
      <li class="nav-item dropdown no-arrow right-navbar-user">
          <a class="nav-link dropdown-toggle user-dropdown-link nav-set-logo" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img alt="Avatar" [src]="profilePic" /> &nbsp;{{username ? username : "Enter your name"}}
          </a>
          <div class="dropdown-menu dropdown-menu-right drop-menu" aria-labelledby="userDropdown" style="width: 176px !important;">
              <a class="dropdown-item" [routerLink]="['/collaborate/dashboard']" [queryParams]="{fileType: 0, folderId: 0}"><img src="/assets/img/dashboard.png" /><span style="font-size: 14px;">Dashboard</span></a>
              <a class="dropdown-item" (click)="toNavigate('/pages/features','page-3')"><img src="/assets/img/rocket_icon.png" />Buy Pro</a>
              <a class="dropdown-item" [routerLink]="['/collaborate/profile']"><i class="fas fa-xl fa-user-circle"></i><img/><span style="font-size: 14px;">Profile</span></a>
              <!-- <div class="dropdown-divider"></div> -->
              <a class="dropdown-item" (click)="logoutUser()" data-toggle="modal" data-target="#logoutModal"><img src="/assets/img/logout.png" />Logout</a>
          </div>
      </li>
  </ul>
</nav>


 <div class="uploader">
  <p-dialog [header]='""' [modal]="true" [dismissableMask]="true" [draggable]="false" [(visible)]="displayModal"
    [style]="{width: '640px'}" [baseZIndex]="10000">
    <app-uploader [files]='files' (showUploaderProgress)="onFileUploaded($event)"
      (hideUploader)="cancelUploader($event)"></app-uploader>
  </p-dialog>
</div>

<!-- <div class="notification-popup" *ngIf="notificationPanel">
  <div *ngIf="loaderContent">
    <ng-lottie width="350px" [options]="optionsLoaderAnimation"></ng-lottie>
  </div>
  <div *ngIf="!loaderContent">
    <div class="no-notification notif-title" *ngIf="notification.length===0">No New Notifications</div>
    <div *ngIf="notification.length!==0">
      <div class="notif-title">Notification</div>
      <ul class="notification-box p-0">
        <li *ngFor="let noti of notification" [class]="noti.isActive? 'notification-active':''">
          <div [class]="noti.isActive? 'dot-active':'dot'"></div>
          <div class="notification-profile">
            <img [src]="noti.profilePicUrl ? profilePic : 'assets/images/user.jpg'">
          </div>
          <div class="top-text-block" [pTooltip]="noti.description" tooltipPosition="left">
            <div class="top-text-heading"><b>{{noti.userName | longName: 10 : 10}}</b><span>{{ noti.createDate | date: "dd/MMM/yyyy 'at' HH:mm"}}</span></div>
            <div class="top-text-light">{{noti.title}}</div>
          </div>
        </li>

      </ul>
      <div class="notifiacation-btn-box d-flex justify-content-center" (click)="openNotificationPanel()" appClickOutside (clickOutside)="clickedOutside2()">
        <i class="pi pi-bell text-white"></i>
        <p-badge class="bell-badge" [hidden]="unreadNotification=='0'"  [value]=unreadNotification></p-badge>
        <button class="btn view-notification" type="button">
          View all Notifications
        </button>
      </div>
    </div>
  </div>
</div> -->