import { ErrorHandler, Injectable } from '@angular/core';
// import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import { Observable } from 'rxjs';
import { S3 } from 'aws-sdk';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { SharedService } from './shared.service';


// @Injectable({
//   providedIn: 'root'
// })
// export class AzureBlobStorageService {
//   progress=0;
//   tempProgress:any;
//   // Enter your storage account name
//   account = "prevueitin";
//   // container name
//   container = "prevueit-doc";
//   sas = environment.sas;
//   blobSASURL = 'https://prevueitin.azureedge.net/prevueit-doc?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2029-08-15T13:45:25Z&st=2023-08-15T05:45:25Z&spr=https&sig=%2BhutQdTcSiKo8dFaiZ63EmqkUIhlU00FKCwkApAwRrk%3D'

//   constructor(private httpClient: HttpClient,private service:SharedService) {
//    }

//   // public postFilesGetURL(data: any): Observable<any> {
//   //   const formData = new FormData();
//   //   formData.append('file', data);
//   //   const url = `${this.APIConstant}`
//   //   return  this.httpClient.post(url,formData,{reportProgress: true,observe: 'events'})
//   // }
  
//   // timeStamp(){
//   // let time = new Date();
//   // return 'File_'+time.getTime();
//   // }


//   // +IMAGES
//   async uploadFiles(content: Blob, name: string, handler: () => void){
//    return await this.uploadBlob(content, name, this.containerClient(), handler)
//   }

//   // public listImages(): Promise<string[]> {
//   //   return this.listBlobs(this.containerClient())
//   // }

//   public downloadFiles(name: string, handler: (blob: Blob) => void) {
//     this.downloadBlob(name, this.containerClient(), handler)
//   }

//   public deleteFiles(name: string, handler: () => void) {
//     this.deleteBlob(name, this.containerClient(), handler)
//   }




//   // -IMAGES
//   async uploadBlob(content: Blob, name: string, client: ContainerClient, handler: () => void): Promise<any>{
//     var blockBlobClient = client.getBlockBlobClient(name);
//     await blockBlobClient.uploadData(content, 
//       {blockSize: 4 * 1024 * 1024,
//         maxSingleShotSize: 50 * 1024 * 1024,
//         concurrency: 20,
//         onProgress: (ev:any) => {this.progress= Math.round((ev.loadedBytes/content.size)*100);this.service.progressValue.next(this.progress);},
//         blobHTTPHeaders: { blobContentType: this.uploadContentType(content.type) } })
//       .then(() =>handler());
//       // if(this.progress===100){
//       return blockBlobClient;
//       // }else{
//       //   return '';
//       // }
//     // return blockBlobClient;
//   }

//   // private async listBlobs(client: ContainerClient): Promise<string[]> {
//   //   let result: string[] = []

//   //   let blobs = client.listBlobsFlat();
//   //   for await (const blob of blobs) {
//   //     result.push(blob.name)
//   //   }
//   //   return result;
//   // }

//   uploadContentType(value:any){
//     // // return 'audio/mp3'
//     if(value.split('/')[0] === 'audio'){
//       return 'video/mp4';
//     }
//     else if(value.split('/')[0] === 'video'){
//       return 'video/mp4';
//     }
//     else{
//       return value;
//     }
//   }

//   public downloadBlob(name: string, client: ContainerClient, handler: (blob: Blob) => void) {
//     const blobClient = client.getBlobClient(name);
//     blobClient.download().then((resp:any) => {
//       resp.blobBody.then((blob:any) => {
//         handler(blob)
//       })
//     })
//   }

//   public deleteBlob(name: string, client: ContainerClient, handler: () => void) {
//     client.deleteBlob(name).then(() => {
//       handler()
//     })
//   }

//   public containerClient(): ContainerClient {
//     return new BlobServiceClient(`https://${this.account}.azureedge.net?${this.sas}`)
//             .getContainerClient(this.container);
//   }
// }

// aws-s3.service.ts

@Injectable({
  providedIn: 'root'
})

export class AWSS3Service {
  progress = 0;
  tempProgress: any;

  // AWS S3 configuration
  bucket = 'prevueit-doc';
  region = 'ap-south-1';
  accessKeyId = 'AKIAY4FN7ANJB6IKB6GZ';
  secretAccessKey = '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';

  s3: S3;
  private abortController: AbortController | null = null;

  constructor(private httpClient: HttpClient, private service: SharedService) {
    // Initialize AWS S3 client
    this.s3 = new S3({
      accessKeyId: this.accessKeyId,
      secretAccessKey: this.secretAccessKey,
      region: this.region
    });
  }
  

  async uploadFiles(content: Blob, name: string, handler: () => void, onProgress: (progress: number) => void): Promise<any> {

    this.abortController = new AbortController();
    const { signal } = this.abortController;

    // Use AWS S3 SDK to upload the file
    const params: S3.Types.PutObjectRequest = {
      Bucket: this.bucket,
      Key: name,
      Body: content,
      // ACL: 'public-read',
    };

    try {
      const uploadResponse = await this.s3.upload(params).on('httpUploadProgress', (progress) => {
        const uploadedBytes = progress.loaded;
        const totalBytes = progress.total;
        const percentProgress = Math.round((uploadedBytes / totalBytes) * 100);
        onProgress(percentProgress);
      }).promise();

  
      // Additional information about the uploaded file
      const fileInfo = {
        name: name,
        fileSize: content.size,
        url: uploadResponse.Location, // Assuming AWS S3 provides the file URL in the response
        // Add any other relevant information
      };
  
      handler(); // Invoke the handler function

       // Check if the download has been aborted
       if (signal.aborted) {
        throw new Error('Download aborted');
      }
  
      return fileInfo; // Return the file information
    } catch (error) {
      console.error('Error uploading file to S3:', error);
      throw error; // Rethrow the error to handle it further if needed
    }
  }


// async downloadFile(name: string, url: string, handler: (blob: Blob) => void): Promise<void> {
//   const params: S3.Types.GetObjectRequest = {
//     Bucket: this.bucket,
//     Key: name,
//   };

//   try {
//     // Initiate the download using the provided service and URL
//     const downloadResult = await this.service.downloadFile(name, url).toPromise();

//     // Check if the download result is successful
//     if (downloadResult.type === HttpEventType.Response) {
//       if (downloadResult.body) {
//         // Create a Blob from the downloaded data
//         const blob = new Blob([downloadResult.body], { type: 'application/octet-stream' });

//         // Call the provided handler function with the downloaded Blob
//         handler(blob);
//       }
//     } else if (downloadResult.type === HttpEventType.DownloadProgress) {
//       // Handle download progress if needed
//       // You can consider providing progress updates to the handler function
//     } else {
//       // Handle other download event types if necessary
//     }
//   } catch (error) {
//     console.error('Error downloading file:', error);
//     // Rethrow the error for further handling, if necessary
//     throw error;
//   }
// }
  

  async deleteFile(name: string, handler: () => void): Promise<void> {
    const params: S3.Types.DeleteObjectRequest = {
      Bucket: this.bucket,
      Key: name,
    };

    try {
      await this.s3.deleteObject(params).promise();
      handler();
    } catch (error) {
      console.error('Error deleting file from S3:', error);
      throw error;
    }
  }

  async uploadFiles1(content: Blob, name: string, handler: () => void, onProgress: (progress: number) => void): Promise<any> {

    this.abortController = new AbortController();
    const { signal } = this.abortController;

    // Use AWS S3 SDK to initiate a multipart upload
    const createMultipartParams: S3.Types.CreateMultipartUploadRequest = {
      Bucket: this.bucket,
      Key: name,
      // ACL: 'public-read',
    };
  
    try {
      const createMultipartResponse = await this.s3.createMultipartUpload(createMultipartParams).promise();

      let constantValue = 0; // Declare and initialize the constant value

      // Calculate the number of parts
      const partSize = 5 * 1024 * 1024; // 5 MB per part (adjust as needed)
      const numParts = Math.ceil(content.size / partSize);
  
      // Upload each part
      const uploadPartPromises = Array.from({ length: numParts }, async (_, partNumber) => {

        const start = partNumber * partSize;
        const end = Math.min(start + partSize, content.size);
  
        const uploadPartParams: S3.Types.UploadPartRequest = {
          Bucket: this.bucket,
          Key: name,
          PartNumber: partNumber + 1, // Part numbers start from 1
          UploadId: createMultipartResponse.UploadId!,
          Body: content.slice(start, end),
        };
  
        const uploadPartResponse = await this.s3.uploadPart(uploadPartParams).promise();

        // Increment the constant value
      constantValue++;

      // Calculate progress based on the constant value and total number of parts
      const progress = (constantValue / numParts) * 100;

      // Update progress
      onProgress(progress);


        // onProgress(((partNumber + 1) / numParts) * 100); // Update progress
  
        return {
          PartNumber: partNumber + 1,
          ETag: uploadPartResponse.ETag,
        };
      });
  
      const uploadedParts = await Promise.all(uploadPartPromises);
  
      // Complete the multipart upload
      const completeMultipartParams: S3.Types.CompleteMultipartUploadRequest = {
        Bucket: this.bucket,
        Key: name,
        MultipartUpload: {
          Parts: uploadedParts,
        },
        UploadId: createMultipartResponse.UploadId!,
      };
  
      await this.s3.completeMultipartUpload(completeMultipartParams).promise();
  
      // Additional information about the uploaded file
      const fileInfo = {
        name: name,
        fileSize: content.size,
        url: `https://${this.bucket}.s3.${this.s3.config.region}.amazonaws.com/${name}`, // Assuming AWS S3 provides the file URL in the response
        // Add any other relevant information
      };
  
      handler(); // Invoke the handler function

       // Check if the download has been aborted
       if (signal.aborted) {
        throw new Error('Download aborted');
      }
  
      return fileInfo; // Return the file information
    } catch (error) {
      console.error('Error uploading file to S3:', error);
      throw error; // Rethrow the error to handle it further if needed
    }
  }

  cancelUpload() {
    if (this.abortController) {
      this.abortController.abort();
      this.abortController = null;
    }
  }

  // Other methods (downloadFiles, deleteFiles, etc.) can be similarly adapted using AWS SDK methods for S3

uploadFile2(blob: Blob, fileName: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const params: S3.PutObjectRequest = {
      Bucket: this.bucket,
      Key: fileName,
      Body: blob,
      ContentType: 'audio/mpeg', // Adjust the content type based on your audio file type
      // ACL: 'public-read', // Set the ACL as needed
    };

    this.s3.upload(params, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data.Location); // The URL of the uploaded file
      }
    });
  });
}
}
