import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, timer} from 'rxjs';
import { APIConstant } from 'src/app/constant/api';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  constructor(private httpClient: HttpClient) { }

  public postFilesGetURL(data: any): Observable<any> {
    const formData = new FormData();
    formData.append('file', data);
    const url = `${APIConstant.postFiles}`
    return  this.httpClient.post(url,formData,{reportProgress: true,observe: 'events'})
  }

  public postFileDetails(fileDetails: any): Observable<any>{
    const url = `${APIConstant.postFileDetails}`
    return this.httpClient.post(url , fileDetails)
  }
}
