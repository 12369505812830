import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { SubHeaderComponent } from './sub-header/sub-header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ToastModule } from 'primeng/toast';
import { ChartModule } from 'primeng/chart';
import { FileUploadModule } from 'primeng/fileupload';
import { DialogModule } from 'primeng/dialog';
import { LottieCacheModule, LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { UploaderComponent } from './uploader/uploader.component';
import { LongNamePipe } from './pipe/long-name.pipe';
import { TooltipModule } from 'primeng/tooltip';
import { InputSwitchModule } from 'primeng/inputswitch';
import { BadgeModule } from 'primeng/badge';
import { EmailValidatorDirective } from './directives/email-validator.directive';
import { TimeAgoPipe } from './pipe/time-ago.pipe';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { SidebarModule } from 'primeng/sidebar';
import { SidebarFileinfoComponent } from './sidebar-fileinfo/sidebar-fileinfo.component';
import { AccordionModule } from 'primeng/accordion';
import { MobileviewSidebarComponent } from './mobileview-sidebar/mobileview-sidebar.component';
import {GalleriaModule} from 'primeng/galleria';
import { GalleryComponent } from './gallery/gallery.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NgImageFullscreenViewModule } from 'ng-image-fullscreen-view';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DndDirective } from './directives/dnd.directive';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SideBarComponent,
    SubHeaderComponent,
    UploaderComponent,
    LongNamePipe,
    EmailValidatorDirective,
    TimeAgoPipe,
    ClickOutsideDirective,
    SidebarFileinfoComponent,
    MobileviewSidebarComponent,
    GalleryComponent,
    DndDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ToastModule,
    BadgeModule,
    ChartModule,
    DialogModule,
    AutoCompleteModule,
    LottieCacheModule,
    ConfirmDialogModule,
    LottieModule,
    InputSwitchModule,
    ButtonModule,
    FileUploadModule,
    OverlayPanelModule,
    TooltipModule,
    AccordionModule,
    SidebarModule,
    GalleriaModule,
    NgxDocViewerModule,
    NgImageFullscreenViewModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SideBarComponent,
    SubHeaderComponent,
    LongNamePipe,
    EmailValidatorDirective,
    ClickOutsideDirective,
    DndDirective,
    SidebarFileinfoComponent,
    UploaderComponent,
    MobileviewSidebarComponent,
    GalleryComponent
  ],
  providers: [],
})
export class SharedModule {}
