import { Component } from '@angular/core';

@Component({
  selector: 'app-mobileview-sidebar',
  templateUrl: './mobileview-sidebar.component.html',
  styleUrls: ['./mobileview-sidebar.component.css']
})
export class MobileviewSidebarComponent {
  public visibleSidebar3 = false;
  activeState: boolean[] = [true, false, false];
  toggle(index: number) { this.activeState[index] = !this.activeState[index]; }
}
