import { environment } from 'src/environments/environment';

const base = environment.BASE;
const basePath = environment.BASE_URL;
const baseCommentPath = environment.BASE_COMMENT_URL;

export const APIConstant = {

    //Admin
    getalluser: `${base}/admin/GetAllUsers`,

    //Comments
    getCommentsById: `${baseCommentPath}GetCommentsByFileId/{iFileId}`,
    postCommentDetails :`${baseCommentPath}AddUpdateComment`,
    postComment :`${baseCommentPath}UploadComment`,
    getLikeComment : `${baseCommentPath}LikeComment/{iCommentId}`,



    //File
    getFilesbyUserId : `${basePath}GetFilesByUploadedUserId/{fileType}/{iUserId}/{iFolderId}`,
    getFileSharedUserEmail : `${basePath}GetFileSharedUserEmail/{userEmail}/{fileType}`,
    getFilebyFileId:`${basePath}GetFileDetailsId/{iFileId}`,
    postFiles : `${basePath}Upload`,
    postFileDetails : `${basePath}UploadFiles`,
    postAddSharedUser:`${basePath}AddSharedUser`,
    getRemoveSharedFile : `${basePath}RemoveSharedUser/{iFileId}/{iFolderId}/{userEmails}`,
    getStoreViewCount : `${basePath}IncrementViewCount/{iFileId}`,
    getStoreViewCountbyUrl : `${basePath}IncrementViewCountbyUrl/{url}`,
    getUpdateFileReview : `${basePath}UpdateReviewedStatus/{iFileId}/{userEmail}`,
    getDeleteFile: `${basePath}DeleteFile/{iFileId}`,
    addRemoveFavourite: `${basePath}AddRemoveFavourites/{iFileFolderId}/{isFavourite}/{isFolder}`,
    renameFileName: `${basePath}RenameFileFolder/{iFileFolderId}/{newName}/{isFolder}`,
    createFolder: `${basePath}CreateFolder`,
    movetoFolder: `${basePath}MoveToFolder/{iFolderId}/{iFileId}`,
    getFolderByUserid: `${basePath}GetFoldersByUserId/{iUserInfoId}`,
    deleteFolder: `${basePath}DeleteFolder/{iFolderId}`,
    fetFolderDetailsWithFile: `${basePath}FetFolderDetailWithFiles/{iFolderId}`,
    duplicateFile : `${basePath}DuplicateFile/{iFileId}/{iUserId}`,
    getSearchFileLists: `${basePath}GetSearchFileLists/{fileType}/{iUserId}/{userEmail}`,


    
    //USER
    emailSignUp: `${basePath}EmailSignUp`,
    verifyUser: `${basePath}VerifyUser`,
    updateUser: `${basePath}UpdateUser`,
    userById: `${basePath}GetUserById/{iUserId}`,
    fileSizeByUserId: `${basePath}GetFileSizeByUserId/{iUserId}`,
    userLogin: `${basePath}UserLogin`,
    postUpdateExternalUser : `${basePath}UpdateExternalUser`,
    postUploadProfilePic : `${basePath}UploadProfilePic`,
    getGetNotificationByUserId:`${basePath}GetNotificationByUserId/{iUserId}`,
    getReadNotificationById:`${basePath}ReadNotification/{iNotificationId}`,
    getGetNewNotification:`${basePath}GetNewNotification/{iUserId}`,
    getSentFilesbyUserId : `${basePath}GetSentFilesByUserId/{iUserId}`
}
