<!-- <div class="app-sidebar-box">
<p-sidebar [(visible)]="visibleSidebar3" position="top" [baseZIndex]="10000">
    <h3>Top Sidebar</h3>
</p-sidebar>



<p-button type="button" class="mr-2 mobile-menubtn" (click)="visibleSidebar3 = !visibleSidebar3" icon="pi pi-arrow-up"></p-button> 
</div> -->
<p-accordion>
  <p-accordionTab header="Header I" [selected]="true">
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
      veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
      commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
      velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
      cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
      est laborum.
    </p>
  </p-accordionTab>
  <p-accordionTab header="Header II">
    <p>
      Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
      doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo
      inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
      Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut
      fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem
      sequi nesciunt. Consectetur, adipisci velit, sed quia non numquam eius
      modi.
    </p>
  </p-accordionTab>
  <p-accordionTab header="Header III">
    <p>
      At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
      praesentium voluptatum deleniti atque corrupti quos dolores et quas
      molestias excepturi sint occaecati cupiditate non provident, similique
      sunt in culpa qui officia deserunt mollitia animi, id est laborum et
      dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio.
      Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil
      impedit quo minus.
    </p>
  </p-accordionTab>
</p-accordion>
<p-button [icon]="activeState[0] ? 'pi pi-minus' : 'pi pi-plus'" (click)="toggle(0)" styleClass="p-button-text" label="Toggle 1st"></p-button>



