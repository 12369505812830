import { Directive, forwardRef } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[appEmailValidator]',
  providers: [{ multi: true, useExisting: forwardRef(() => EmailValidatorDirective), provide: NG_VALIDATORS }]
})
export class EmailValidatorDirective implements Validator{

  constructor() { }
  
  registerOnValidatorChange?(fn: () => void): void {
  }

  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    const sharedEmail = control.value.replace(/\s+/g, '')
    if(sharedEmail.indexOf(',') !== -1){
      let emailData = sharedEmail.split(',');
      if(emailData.find((email:string)=> !email.match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$"))){
        return { emailInvalid: true };
      }else{
        return null;
      }
    }else{
      if(!sharedEmail.match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$")){
        return { emailInvalid: true };
      }else{
        return null;
      }
    }
  }

}
