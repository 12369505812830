// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  recaptcha: {
    siteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  },
  sas : '?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2029-08-15T13:45:25Z&st=2023-08-15T05:45:25Z&spr=https&sig=%2BhutQdTcSiKo8dFaiZ63EmqkUIhlU00FKCwkApAwRrk%3D',
  //Dev Website
  // BASE : "https://dev-prevueit-api.azurewebsites.net/api/prevueit",
  // BASE_URL: "https://dev-prevueit-api.azurewebsites.net/api/prevueit/user/",
  // BASE_COMMENT_URL: "https://dev-prevueit-api.azurewebsites.net/api/prevueit/comment/"

 //Live Website
  // BASE : "https://prevueit-api.azurewebsites.net/api/prevueit",
  // BASE_URL: "https://prevueit-api.azurewebsites.net/api/prevueit/user/",
  // BASE_COMMENT_URL: "https://prevueit-api.azurewebsites.net/api/prevueit/comment/"

  // Live Website
  BASE : "https://app.bharatransfer.com/api/prevueit",
  BASE_URL: "https://app.bharatransfer.com/api/prevueit/user/",
  BASE_COMMENT_URL: "https://app.bharatransfer.com/api/prevueit/comment/"

  //Live Website
  // BASE : "https://localhost:44312/api/prevueit",
  // BASE_URL: "https://localhost:44312/api/prevueit/user/",
  // BASE_COMMENT_URL: "https://localhost:44312/api/prevueit/comment/"

};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
