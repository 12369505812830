import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'longName'
})
export class LongNamePipe implements PipeTransform {

  transform(value: any, limit:any , limit2:any) {
    if(value.length > limit){
      return value.substr(0,limit2)+'...'
    }else{
      return value
    }
  }

}
