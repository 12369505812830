import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode from "jwt-decode";
import { Observable } from 'rxjs';
import { CollaborateService } from 'src/app/collaborate/services/collaborate.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['../../../../css/mystyle.css',
  '../../../../vendor/bootstrap/css/bootstrap.min.css',
  '../../../../vendor/fontawesome-free/css/all.min.css']
})
export class HeaderComponent implements OnInit,AfterViewInit  {
  menuShow = false;
  userInfo: any;
  loggedIn = localStorage.getItem('status')
  profilePic:any = 'https://www.bharatransfer.com/assets/img/user-icon.png';
  selectedcom: any;
  constructor(private router: Router, private collaboratorService: CollaborateService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    this.getUserInfo();
  }

  getUserInfo() {
    let localData = JSON.parse(localStorage.getItem('userInfo') || '{}')
    this.collaboratorService.getUserById(localData.userId).subscribe(async res => {
      this.userInfo = res.responseData;
      if(this.userInfo && this.userInfo?.profilePicURL !== null){
        this.profilePic = await this.loadInitialFileData1(this.userInfo.profilePicURL);
      } else{
        this.profilePic = 'https://www.bharatransfer.com/assets/img/user-icon.png';
      }
    })
  }

  async loadInitialFileData1(url: any) {
    const s3ObjectKey = url;
    // Generate a pre-signed URL for the S3 object
    const preSignedUrl = await this.generatePreSignedUr(s3ObjectKey).toPromise();
  
    // Now, you can use the pre-signed URL to fetch the file
    this.selectedcom = preSignedUrl;
    return this.selectedcom;
  }

  generatePreSignedUr(s3ObjectKey: string): Observable<string> {

    const s3Url = s3ObjectKey;
  
    // Use your backend service or AWS SDK to generate a pre-signed URL
    return this.collaboratorService.generatePreSignedUrl(s3Url);
  }

  dashboardOption() {
    if (localStorage.getItem('userInfo')) {
      return true;
    } else {
      return false;
    }
  }

  clickedOutside(): void {
    this.menuShow = false;
  }

  logoutUser() {
    localStorage.removeItem('userInfo');
    this.profilePic = 'https://www.bharatransfer.com/assets/img/user-icon.png';
    this.userInfo = '';
    localStorage.setItem('status', 'logged-out')
    this.router.navigate(['/auth/login']);
  }

  toNavigate(navigateTo:any ,value:any){
    if(value){
      this.router.navigate([navigateTo], { fragment: value });
    }else{
      this.router.navigate([navigateTo]);
    }
  }

}


