<!-- <img *ngIf="selectedFile?.fileType === 'image'" [src]="selectedFile?.fileUrl" alt="Photo" />
<video *ngIf="selectedFile?.fileType === 'video'" width="400" preload="metadata" controls> -->
<p-header>
    <div class="file-details-box d-flex justify-content-between row w-full align-items-center pt-3">
        <div class="file-name-box col-6 p-0">
            <h4 class="text-white"></h4>
        </div>
        <div class="col-6 d-flex justify-content-between p-0">
            <div class="file-select-title">
                <h5 class="">{{selectedFile?.fileOriginalName}} </h5>
            </div>
            <div class="file-option d-flex">
                <button class="mr-1"><i *ngIf="selectedFile?.isFavourite == false" class="pi pi-thumbs-up"
                        (click)="addRemoveFavourite(selectedFile.iFolderId,selectedFile.iFileId, true,selectedFile.isFolder)"></i>
                    <i *ngIf="selectedFile?.isFavourite == true" class="pi pi-thumbs-up-fill"
                        (click)="addRemoveFavourite(selectedFile.iFolderId,selectedFile.iFileId, false,selectedFile.isFolder)"></i></button>
                <button class="mr-1" (click)="openModal()"><i class="pi pi-share-alt"></i></button>
                <button (click)="toDownload(selectedFile.fileOriginalName,selectedFile.fileUrl)"><i
                        class="pi pi-download "></i></button>
            </div>
        </div>
    </div>
</p-header>
<div class="gallery-box" *ngIf="selectedFile != undefined">

    <div class="gallery-video-box mt-2">
        <div class="file-box">

            <img *ngIf="selectedFile?.fileType === 'image'" [src]="signedUrls[selectIndex - 1]" alt="Photo" />
            

            <video *ngIf="selectedFile?.fileType === 'video' || selectedFile?.fileType === 'audio' "
                [src]="signedUrls[selectIndex - 1]" type='video/mp4' controls>
            </video>


            <div class="card preview-card" *ngIf="selectedFile?.fileType === 'document'">
                <div class="document-view" *ngIf="contentType(selectedFile.contentType) !== 'X-ZIP-COMPRESSED'">
                    <ngx-doc-viewer class="document-box"
                        [viewerUrl]="'https://docs.google.com/gview?url=%URL%&embedded=true'"
                        [url]="(signedUrls[selectIndex - 1])" viewer="google"></ngx-doc-viewer>
                </div>
                <div class="d-flex justify-content-center align-items-center flex-column zip-view-box"
                    *ngIf="contentType(selectedFile.contentType) === 'X-ZIP-COMPRESSED'">
                    <div class="zip-view d-flex justify-content-center align-items-center">
                        <img [src]="getImagetoFileType(selectedFile.contentType)">
                    </div>
                    <div class="pb-5">
                        <p class="pb-5 pt-3">Download Only File</p>
                    </div>
                </div>
            </div>


        </div>
        <!-- Next & Previous Buttons -->
        <div class="video-data-box d-flex align-items-center">
            <button [disabled]="selectIndex == 1" (click)="previousFile()"><i class="fa fa-caret-left"
                    aria-hidden="true"></i></button>
            <div class="count-box mx-2">
                {{selectIndex}} To {{displayFiles?.length}}
            </div>
            <button [disabled]="selectIndex == displayFiles?.length" (click)="nextFile()"><i class="fa fa-caret-right"
                    aria-hidden="true"></i></button>
        </div>
        <!-- Next & Previous Buttons -->
    </div>



    <div class="d-flex justify-content-center my-3 view-gallery-btn mb-5">
        <button *ngIf="contentType(selectedFile?.contentType) !== 'X-ZIP-COMPRESSED'"
            (click)="videoPreview(selectedFile?.iFileId)">View In Details</button>
            <!-- <i *ngIf="selectedFile?.fileType === 'image'" class="pi pi-window-maximize" (click)="showLightbox()"></i> -->
    </div>
</div>


<!-- ------------------------Share File Modal ------------------------- -->
<div class="sent-share-dailogs" *ngIf="selectedFile">
    <p-dialog
        [header]='"Share "+ (selectedFile.isFolder === true ? selectedFile.folderName : selectedFile.fileOriginalName)'
        [modal]="true" [dismissableMask]="true" [draggable]="false" [(visible)]="displayBasic" [style]="{width: ''}"
        [style]="{height: ''}">

        <form [formGroup]="sendFileForm" class="form-confirmbox">
            <div class="email-box pt-2 justify-content-between">
                <div class="emailenter-box">
                    <div>
                        <input type="text" pInputText class="p-inputtext" placeholder="Enter email…"
                            formControlName="email" appEmailValidator>
                    </div>
                </div>
                <div class="share-btn-box">
                    <p-button label="Share" (click)="closeDialog()" class="shre-label"></p-button>
                </div>
            </div>
            <span class="shared-linkfile">
                <div class="text-danger text-center"
                    *ngIf="isSubmitted && sendFileForm.get('email')?.hasError('required')">
                    Please Entre E-mail
                </div>
                <div class="text-danger text-center"
                    *ngIf="isSubmitted &&  sendFileForm.get('email')?.hasError('emailInvalid') && !sendFileForm.get('email')?.hasError('required')">
                    Plese Enter Valid E-mail
                </div>
            </span>
            <div class="shared-box w-full d-flex justify-content-between align-items-center mt-2 mb-3">
                <div class="d-flex align-items-center w-full" *ngIf="selectedFile.fileUserDetails.length==0">
                    <div class="shared-admin-img">
                        <img [src]="profilePic" alt="profile-pic">
                    </div>
                    <div class="shared-detail">
                        <h6>{{selectedFile.ownerEmail}}</h6>
                        <!-- <p>Tony@example.com</p> -->
                    </div>
                </div>
                <div class="d-flex align-items-center w-full" *ngIf="selectedFile.fileUserDetails.length>0">
                    <span class="d-flex align-items-center">
                        <div class="shared-admin-img">
                            <img [src]="ownerprofilePic">
                        </div>
                        <div class="shared-detail">
                            <h6>{{selectedFile.fileUserDetails[0]?.ownerUserName}}</h6>
                            <p>{{selectedFile.fileUserDetails[0]?.ownerEmail}}</p>
                        </div>
                    </span>
                </div>
                <div class="admin-box">
                    <button>Admin</button>
                </div>
            </div>
            <div class="shared-title w-full d-flex text-left" *ngIf="selectedFile.fileUserDetails.length>0">
                Shared with
            </div>
            <div class="shared-user-box" *ngIf="selectedFile.fileUserDetails.length>0">
                <div class="shared box w-full align-items-center justify-content-start mb-4"
                    *ngFor="let email of selectedFile.fileUserDetails; let index = index">
                    <div class="d-flex align-items-center w-full">
                        <div class="shared-admin-img">
                            <img *ngIf="sharetoprofilePic[index]" [src]=sharetoprofilePic[index] class="icon">
                            <img *ngIf="!sharetoprofilePic[index]" src="/src/assets/img/user-icon.png" class="icon">
                        </div>
                        <div class="shared-detail">
                            <h6>{{email.sendToUserName}}</h6>
                            <p>{{email.sendToEmail}}</p>
                        </div>
                        <div>
                            <i class="pi pi-times-circle pr-2" (click)="removeUser(email)"></i>
                        </div>
                    </div>
                </div>
                <!-- <div class="shared box w-full align-items-center justify-content-start mb-4">
                    <div class="d-flex align-items-center w-full">
                        <div class="shared-admin-img">
                            <img src="/assets/images/36_Robot.png">
                        </div>
                        <div class="shared-detail">
                            <h6>Bod Dean</h6>
                            <p>bod@example.com</p>
                        </div>

                    </div>
                </div> -->
            </div>
            <ng-container *ngIf="selectedFile.isFolder == false">
                <div class="link_box pt-3 ">
                    <input type="text" pInputText class="p-inputtext" [value]=shortUrl readonly="true">

                    <button pButton type="button" label="Copy link" class="copy-link" (click)="copyFileURL()"></button>
                </div>
                <div>
                    <p class="allow-text mb-5">Copied link will allow user to download the file.
                        <span class="copied-text">{{fileCopied ? 'Copied':''}}</span>
                    </p>
                </div>
            </ng-container>
        </form>
    </p-dialog>
</div>
<!-- ------------------------Share File Modal ------------------------- -->

<!-- --------------------------Progressbar Start------------------------- -->
<div >
    <!-- <p class="mb-1">File Uploading - ({{tempDownloadProgressValue}}%)</p> -->
    <!-- <p-progressBar [value]="tempDownloadProgressValue" [showValue]="false"></p-progressBar> -->
    <!-- <div class="download-popup" *ngFor="let item of tempProgress; let i = index" [ngStyle]="{'bottom': i * 8.7 + 'em'}">
        <div class="">
         <i class="fa fa-cloud-upload" aria-hidden="true"></i>
        </div>
        <div class="" style="width:100%;" >
        <div class="download-title d-flex justify-content-start p-2">
            <h3>Download File</h3> 
        </div>
        <div class="progress-box-download p-2" >
         <p class="mb-1">{{item.fileName}} Downloading - ({{item.tempDownloadProgressValue}}%)</p>
         <p-progressBar [value]="item.tempDownloadProgressValue" [showValue]="false"></p-progressBar>
        </div>
        </div>
     </div> -->
<!-- </div>
<div *ngIf="tempProgress">
    <div class="download-popup mt-0" *ngIf="tempDownloadProgressValue !== 100">
      <div>
        <!-- Add your icon or styling here 
      </div>
      <div style="width: 100%;">
        <div class="download-title d-flex justify-content-start p-2">
          <h3>Download File</h3>
        </div>
        <div class="progress-box-download p-2">
          <p class="mb-1">Download file - ({{ tempDownloadProgressValue }}%)</p>
          <p-progressBar [value]="tempDownloadProgressValue" [showValue]="false"></p-progressBar>
        </div>
      </div>
    </div> -->
     <!-- <div class="fileupload-popup m-2" *ngIf="tempProgressValue===100">
        <div class="mr-3">
          <i class="fa fa-cloud-upload" aria-hidden="true"></i>
         <ng-lottie width="50px" [options]="optionsDone"></ng-lottie>
        </div>
        <div>
         <p class="mb-0 text-white">File uploaded</p>
        </div>
     </div> -->
</div>
<!-- --------------------------Progressbar End------------------------- -->

<!-- Image Full Screen Start -->
<ng-image-fullscreen-view [images]="displayFiles" [imageIndex]="selectedImageIndex" [paginationShow]="false" [show]="showFlag"
    (close)="closeEventHandler()"></ng-image-fullscreen-view>

<!-- Image Full Screen End -->