import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AuthguardGuard } from './authguard.guard';

const routerOptions: ExtraOptions = {
  anchorScrolling: "enabled"
  //scrollPositionRestoration: "enabled"
};

const routes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'layout',
    loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),
    canActivate: [AuthguardGuard]
  },
  // {
  //   path: 'files',
  //   loadChildren: () => import('./collaborate/collaborate.module').then(m => m.CollaborateModule),
  //   canActivate: [AuthguardGuard]
  // },
  {
    path: 'collaborate',
    loadChildren: () => import('./collaborate/collaborate.module').then(m => m.CollaborateModule),
    canActivate: [AuthguardGuard]
  },
  {
    path: '',
    loadChildren: () => import('./static-pages/static-pages.module').then(m => m.StaticPagesModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes ,routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
