import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'Angular-Project-Demo';

  constructor(private bnIdle: BnNgIdleService, private router: Router) { // initiate it in your component constructor
    this.bnIdle.startWatching(86400).subscribe((res) => {
      if(res) {
        localStorage.clear();
        this.router.navigate(['/auth/login']);
      }
    })
  }

  ngOnInit(): void {
    if (localStorage.getItem('status') == undefined) {
      localStorage.setItem('status','logged-out')
    }
  }
}
