import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['../../../../css/mystyle.css',
  '../../../../vendor/bootstrap/css/bootstrap.min.css',
  '../../../../vendor/fontawesome-free/css/all.min.css']
})
export class FooterComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  toNavigate(navigateTo:any ,value:any){
    if(value){
      this.router.navigate([navigateTo], { fragment: value });
    }else{
      this.router.navigate([navigateTo]);
    }
  }

}
